<template>
  <div>
    <b-card>
      <b-row class="d-flex flex-row justify-content-center rounded-lg">
        <b-col>
          <b-form ref="form">
            <b-row class="rounded-lg p-2">
              <b-col cols="12" class="d-flex flex-row justify-content-between">
                <h3>Basic Information</h3>
                <b-link
                  v-if="mode === 'show' && $can('update', 'Company')"
                  :to="`/supervisor/edit/${$route.params.id}`"
                  class="btn edit-btn"
                >
                  Edit <img src="@/assets/images/icons/edit-3.png" alt="img"
                /></b-link>
              </b-col>

              <b-col cols="12" md="12">
                <b-row>
                  <b-col cols="12" md="6">
                    <label for=""
                      >First Name <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="First name is required"
                      :state="fnameState"
                      label-for="fname"
                    >
                      <b-form-input
                        id="name"
                        v-model="supervisor.first_name"
                        placeholder="Enter first Name"
                        required
                        :state="fnameState"
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        v-debounce:300="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for=""
                      >Last Name <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Last name is required"
                      :state="nameState"
                      label-for="fname"
                    >
                      <b-form-input
                        id="name"
                        v-model="supervisor.last_name"
                        placeholder="Enter last Name"
                        required
                        :state="nameState"
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="">
                      Contact Number <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Contact number field is required"
                      :state="contactState"
                      label-for="fname"
                    >
                      <b-form-input
                        id="fname"
                        v-model="supervisor.phone"
                        placeholder="Enter Contact Number"
                        required
                        :state="contactState"
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="Last Name"
                      >Email Address <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Email Address is required"
                      :state="mailState"
                    >
                      <b-form-input
                        type="email"
                        v-model="supervisor.email"
                        placeholder="Enter Email Address"
                        required
                        :state="mailState"
                        class="data-text bg-light"
                        :disabled="mode === 'show'"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="">
                      Designation
                      <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Designation  field is required"
                      label-for="fname"
                      :state="designationState"
                    >
                      <b-form-input
                        id="fname"
                        v-model="supervisor.supervisor.designation"
                        placeholder="Enter Designation "
                        required
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        :state="designationState"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="">
                      Address
                      <span class="text-danger">*</span></label
                    >
                    <b-form-group
                      invalid-feedback="Address  field is required"
                      label-for="fname"
                      :state="addressState"
                    >
                      <b-form-input
                        id="fname"
                        v-model="supervisor.supervisor.address"
                        placeholder="Enter Address "
                        required
                        class="data-text bg-light"
                        :readonly="mode === 'show'"
                        :state="addressState"
                        @keyup="checkFormValidity"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col class="text-right">
                    <button
                      @click.prevent="updateSupervisor"
                      v-if="mode === 'edit' && $can('update', 'Supervisor')"
                      class="btn edit-btn"
                    >
                      Update
                    </button>
                    <button
                      @click.prevent="registerSupervisor"
                      v-if="mode === 'create' && $can('create', 'Supervisor')"
                      class="btn edit-btn"
                    >
                      Create
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { toast } from "@/utils/toast";
import {
BButton,
BCard,
BCol,
BForm,
BFormCheckbox,
BFormCheckboxGroup,
BFormDatepicker,
BFormFile,
BFormGroup,
BFormInput,
BFormSelect,
BFormTextarea,
BRow,
} from "bootstrap-vue";
export default {
  components: {
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow,
  },

  props: {
    mode: {
      type: String,
      default: "create",
    },
    supervisor: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      nameState: null,
      fnameState: null,
      contactState: null,
      mailState: null,
      designationState: null,
      addressState: null,
    };
  },
  methods: {
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();

      if (
        this.supervisor.first_name !== "" &&
        this.supervisor.first_name !== undefined
      ) {
        valid = true;
        this.fnameState = valid;
      } else {
        valid = false;
        this.fnameState = valid;
      }
      if (
        this.supervisor.last_name !== "" &&
        this.supervisor.last_name !== undefined
      ) {
        valid = true;
        this.nameState = valid;
      } else {
        valid = false;
        this.nameState = valid;
      }
      if (this.supervisor.phone !== "" && this.supervisor.phone !== undefined) {
        valid = true;
        this.contactState = valid;
      } else {
        valid = false;
        this.contactState = valid;
      }
      if (this.supervisor.email !== "" && this.supervisor.email !== undefined) {
        valid = true;
        this.mailState = valid;
      } else {
        valid = false;
        this.mailState = valid;
      }
      // check supervisor.email is a valid email or not
      if (valid) {
        // check email validation with regex
        const emailRegex = /\S+@\S+\.\S+/;
        valid = emailRegex.test(this.supervisor.email);
        this.mailState = valid;
      }
      if (
        this.supervisor.supervisor.designation !== "" &&
        this.supervisor.supervisor.designation !== undefined
      ) {
        valid = true;
        this.designationState = valid;
      } else {
        valid = false;
        this.designationState = valid;
      }
      if (
        this.supervisor.supervisor.address !== "" &&
        this.supervisor.supervisor.address !== undefined
      ) {
        valid = true;
        this.addressState = valid;
      } else {
        valid = false;
        this.addressState = valid;
      }
      return valid;
    },
    registerSupervisor() {
      if (!this.checkFormValidity()) {
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Please fill all the supervisor fields"
        );
      } else {
        this.supervisor.user_id = this.$route.params.id;
        this.supervisor.address = this.supervisor.supervisor.address;
        this.supervisor.designation = this.supervisor.supervisor.designation;
        this.$store
          .dispatch("company/createCompanyUser", this.supervisor)
          .then((res) => {
            if (res) {
              toast(
                "Success",
                "CheckIcon",
                "success",
                "Supervisor created successfully"
              );
              this.$router.push({
                path: `/company/view/${this.$route.params.id}`,
              });
            }
          })
          .catch((err) => {
            toast(
              "Error",
              "AlertTriangleIcon",
              "danger",
              err.response?.data?.message || "Supervisor creation failed"
            );
          });
      }
    },
    updateSupervisor() {
      if (!this.checkFormValidity()) {
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Please fill all the supervisor fields"
        );
      } else {
        this.supervisor.user_id = this.$route.params.id;
        this.supervisor.address = this.supervisor.supervisor.address;
        this.supervisor.designation = this.supervisor.supervisor.designation;
        this.$store
          .dispatch("company/updateCompanyUser", this.supervisor)
          .then((res) => {
            if (res) {
              toast(
                "Success",
                "CheckIcon",
                "success",
                "Supervisor updated successfully"
              );
              this.$router.push({
                path: `/company/view/${this.$route.params.id}`,
              });
            }
          })
          .catch((err) => {
            toast(
              "Error",
              "AlertTriangleIcon",
              "danger",
              err.response?.data?.message || "Supervisor creation failed"
            );
          });
      }
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.edit-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  color: white;
}
.data-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  color: #01185e;
}
</style>
